.navbar-brand img {
  width: 100px;
}
.nav-sm-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}
.nav-sm-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  background: var(--theme-color);
  color: var(--black-color);
  margin-left: 10px;
  font-size: 1.4rem;
}
#offcanvasNavbar-expand-lg .nav-link {
  color: var(--black-color) !important;
}
.dropdown-toggle::after {
  border: none !important;
  content: "\f107" !important;
  font-family: FontAwesome;
  vertical-align: 0 !important;
  font-weight: bold;
}
.dropdown-item.active, .dropdown-item:active{
  background: transparent !important;
}
@media (min-width: 993px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: -8px !important;
  }
  
}

@media (max-width:992px) {
  .website-navbar.offcanvas {
    width: 80% !important;
  }
  .website-navbar .btn-close {
    filter: inherit;
  }  
  .nav-sm-links {
    margin-top: .5rem;
  }
  .nav-sm-links li:first-child a {
margin: 0;
  }
  .website-navbar .dropdown-menu{
    border: none;
    padding: 0;
  }
  .website-navbar .dropdown-menu .dropdown-item{
    padding-left: 10px;
  }
}