.visitorformbg {
    background: rgba(222, 222, 222, 0.56) !important;
    height: 100vh !important;
    overflow: hidden !important;
}

.visitorformbg-ipe {
    background: #ececec !important;
    height: auto !important;
}

.visitor-input {
    border-radius: 0.374rem !important;
    padding: 10px !important;
    height: 47px !important;
    font-size: 15px !important;
    color: #86788d !important;
}

.visitor-text-area {
    border-radius: 0.374rem !important;
    padding: 10px !important;
    font-size: 15px !important;
    color: #000 !important;
}

.visitor-btn {
    border-radius: 0.374rem !important;
    border: none !important;
    background: #ffcc00 !important;
    padding: 11px 23px !important;
    font-size: 15px !important;
    font-weight: 500;
}

.visitor-resident-value {
    font-size: 15px !important;
    color: #86788d !important;
}

.visitorformbg .ant-select-selector {
    border: none !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.visitorformbg .ant-select-selection-search {
    inset-inline-start: 0 !important;
}

@media (min-width: 410px) {
    .Commercial-phn {
        display: none !important;
    }
}

@media (max-width: 410px) {
    .Commercial-tab {
        display: none !important;
    }
}