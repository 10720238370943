.top-header-swiper {
    padding-bottom: 2.5rem !important;
}

.top-header-swiper .swiper-pagination-bullet-active {
    background-color: var(--color-theme) !important;
}

.client-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.client-logo img {
    width: 175px;
}

.top-header {
    /* margin-bottom: 1.875rem; */
    background-color: #ffffff;
    transition: all 0.5s ease-in-out;
    padding: 1rem;
    border-radius: 0.375rem;
    height: calc(100% - 30px);
    /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap; */
}

.top-header .top-header-icon {
    height: 3.75rem;
    width: 5.75rem;
    line-height: 3.4rem;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 1.5rem;
}

.top-header p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: #303972;
}

/* .top-header-card:nth-child(1) .top-header-icon {
    background-color: #94618e;
} */