.thank-page-template {
  background-color: #f2f2f2;
}

.thank-you-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank-you-wrapper > .container {
  width: 100%;
  max-width: 780px;
  /* margin: 0 auto; */
}

.thank-you-wrapper {
  text-align: center;
}

.thank-you-page-content {
  position: relative;
  float: left;
  width: 100%;
  background: #f2f2f2;
  padding: 90px;
  margin: 30px 0;
  box-sizing: border-box;
}

.thank-you-page-content::before,
.thank-you-page-content::after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.thank-you-page-content::after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

html body .thank-you-wrapper .container > .row {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.thank-you-page-logo {
  float: left;
  width: 100%;
}

.thank-you-copy p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.thank-you-page-content h1 {
  position: relative;
  width: 100%;
  float: left;
  padding-top: 110px;
  font-size: 40px;
  font-weight: 200;
  line-height: 40px;
}

.thank-you-page-content p {
  font-size: 1.2rem;
  margin-bottom: 45px;
}

.thank-you-page-content h1::before {
  content: "\f00c";
  top: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  left: 50%;
  position: absolute;
  font-family: "FontAwesome";
  font-size: 60px;
  text-align: center;
  float: left;
  width: 100px;
  color: green;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 2px solid green;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -ms-border-radius: 100%;
}

.thank-you-page-content .btn {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 25px;
}
.thank-you-wrapper > .container .logo-img {
  /* min-width: 250px; */
  max-width: 150px;
}
