/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --theme-color: #fff501;
  --white-color: #fff;
  --black-color: #000;
}

body {
  font-family: "Roboto", sans-serif !important;
}

a {
  text-decoration: none !important;
}

.theme-btn {
  display: inline-block;
  border-radius: 5px;
  padding: 8px 15px;
  /* background: var(--theme-color); */
  background: #212529;
  color: var(--white-color) !important;
  border: none;
}

.main-heading {
  position: relative;
  margin-bottom: 1.5rem;
}

.main-heading h1 {
  font-size: 2.2rem;
  margin: 0;
}

.main-heading h2 {
  margin: 0;
}

.main-heading p {
  margin: 0;
  font-size: 1.2rem;
}

.main-heading::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 100px;
  border-radius: 5px;
  background: var(--theme-color);
}

.main-heading-left::after {
  left: 0;
  transform: translateX(0);
}

.swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
}

.text-justify {
  text-align: justify !important;
}

.events-projects-img img {
  width: 32%;
  height: 275px;
}

/* .events-page-projects {
  border: 1px solid #e3e3e3;
  height: 100%;
  border-radius: 5px;
  padding: 1rem;
}
.events-page-projects a{
  margin-top: 1rem;
} */

.singapore-parallax-section {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./assest/img/singapore-parallax-bg.png);
  background-attachment: fixed;
  background-size: cover;
}

.abu-dhabi-parallax-section {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./assest/img/Abu-Dhabi-parallax-bg.png);
  background-attachment: fixed;
  background-size: cover;
}

.kenya-parallax-section {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./assest/img/Kenya-parallax-bg.png);
  background-attachment: fixed;
  background-size: cover;
}

.singapore-parallax-section h2 {
  font-size: 3rem;
}

.deals-card,
.visitor-registration-form {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

.deals-card {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.deals-card img {
  width: 80px;
  height: 100%;
  object-fit: contain;
  margin-right: 1rem;
}

.locatios-section img {
  height: 80px;
  width: 100%;
}

.visitor-ul li {
  list-style: disc;
}

.privacy-policy-page li {
  list-style: disc;
}

.visitor-registration-page-form .main-heading.main-heading-left {
  display: none;
}

@media (max-width: 1500px) {
  .events-projects-img img {
    height: 236px;
    object-fit: cover;
  }
}

@media (max-width: 1350px) {
  .events-projects-img img {
    height: 193px;
    /* object-fit:   fill; */
    object-position: 0 -70px;
  }
}

@media (max-width: 568px) {
  .main-heading h1 {
    font-size: 1.7rem;
  }

  .events-projects-img {
    flex-wrap: wrap;
  }

  .events-projects-img img {
    width: 100%;
    object-position: 0;
    height: auto;
    margin-bottom: 1.5rem;
  }

  .deals-card {
    margin-bottom: 1.5rem;
  }

  .footer-padding .container-fluid.px-lg-4.bg-dark.py-2 {
    padding-bottom: 4rem !important;
  }
}


/* ******** Filter Website ****** */
.fiter-website-name {
  top: 110px;
  right: 50px;
}


/* *** jarata */

/* button a {
  display: inline-block;
  border-radius: 5px;
  padding: 8px 15px;
  background: var(--theme-color);
  background: #212529;
  color: var(--white-color) !important;
} */