.margin-left {
    margin-left: 6rem;
    margin-right: 1.1rem;
    transition: 0.5s;
}

.bodycollapse .margin-left {
    margin-left: 16.7rem;
}

#main-wrapper.show {
    opacity: 1;
}

#main-wrapper {
    opacity: 0;
    transition: all 0.25s ease-in;
    position: relative;
    background-color: #e6ebee;
}

.main-dashboard {
    background-color: #e6ebee;
}

.default-card {
    /* margin-bottom: 1.875rem; */
    background-color: #ffffff;
    transition: all 0.5s ease-in-out;
    padding: 1rem;
    border-radius: 0.375rem;
    height: 100%;
}

.default-card h6 {
    color: #000;
    margin-bottom: 1rem;
}

.top-header-card {
    display: flex;
    /* align-items: center; */
    /* width: 25%; */
    /* justify-content: center; */
}

.latest-ouotes-table svg {
    /* font-size: 1rem; */
    color: #ffffff;
    border-radius: 0.3rem;
    background-color: #aa7ea5;
    width: 30px;
    height: 30px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-left: 10px;
    cursor: pointer;
}

.latest-ouotes-table svg:last-child {
    padding: 5px;
}

.latest-ouotes-table .mdb-datatable-filter {
    width: 100% !important;
}
.captcha-rotate-icon{
    right: 30px !important;
    top: 5px;
  }